<template>
  <div>
    <div v-if="row">
      <a
        @click="reserveClub('desktop', row.title)"
        class="btn btn-alt d-none d-sm-inline"
      >
        BOOK NOW
      </a>
      <a
        @click="reserveClub('mobile', row.metadata.code)"
        class="btn btn-alt d-inline-xs d-sm-none"
      >
        BOOK NOW
      </a>
    </div>

    <div v-else>
      <a
        class="btn btn-alt font-weight-bold d-none d-sm-inline"
        @click="reserveGeneric('desktop')"
      >
        📲 INFO & RESERVATIONS
      </a>
      <a
        class="btn btn-alt font-weight-bold d-inline-xs d-sm-none"
        @click="reserveGeneric('mobile')"
      >
        📲 INFO & RSVP
      </a>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";

const BASE_MODAL = {
  backdrop: true,
  showCloseButton: true,
  showDenyButton: true,
  showConfirmButton: true,
  confirmButtonText: 'WhatsApp',
  denyButtonText: 'SMS',
  imageWidth: 250,
  width: 350,
  imageHeight: 250,
  confirmButtonColor: '#2e3241',
  denyButtonColor: '#2e3241',
  confirmButtonClass: 'btn btn-block',
  denyButtonClass: 'btn btn-block',
  background: '#23bac4',
};

export default {
  name: 'RsvpBeachClubsViaDialog',
  props: ['row', 'config'],
  computed: {
    genericMessage() {
      return this.message || 'Hey there, how can we help you today?';
    },
    clubSpecificMessage() {
      return this.message || 'CLUB_RSVP:%20{{club}}%0a--------%0aFULL_NAME:%20%0aDATE:%20%0aARRIVAL_TIME:%20%0aGROUP_SIZE:%20';
    },
  },
  methods: {
    reserveGeneric(device) {
      this.rsvpDialog('generic', device);
      this.onFreeConciergeClick(device);
    },
    reserveClub(device, club) {
      this.rsvpDialog('club', device, club);
      this.onFreeConciergeClick(device);
    },
    onFreeConciergeClick(channel) {
      window.ga('send', 'event', 'reservations', 'Beach Clubs: Info & Reservations', 'Main', 50);
      this.$rollbar.info('Beach Clubs: Info & Reservations:' + channel);
    },
    rsvpDialog(type, device, club = '') {
      let options = Object.assign({}, BASE_MODAL, {
        html: this.getDialogHtml(type, club),
        footer: this.mailFooter(club),
      });
      Swal.fire(options).then((result) => {
        if (result.isConfirmed) {
          if (device === 'desktop') {
            this.showQR(club, this.waQR);
          } else {
            open(`https://wa.me/524421867547?text=${this.getMessage(type, club)}`);
          }
        } else if (result.isDenied) {
          if (device === 'desktop') {
            this.showQR(club, this.smsQR);
          } else {
            open(`sms:+524421867547&body=${this.getMessage(type, club)}`);
          }
        }
      });
    },
    getDialogHtml(type, club) {
      if (type === 'generic') {
        return `<h5 class="tulum-party-title-alt">ENJOY TULUM</h5>
                <p style="font-size: 0.8rem;margin-top: -10px;">${this.config ? this.config.caption : 'Single Contact for All Clubs. <br>'}<hr>TALK TO US<br></p>`;
      }
      return `<h5 class="tulum-party-title-alt">${club}</h5>
              <p style="font-size: 0.8rem;margin-top: -15px;">Information & Reservations</p><hr>`;
    },
    getMessage(type, club) {
      if (type === 'generic') {
        return this.genericMessage;
      }
      return this.replaceTag(this.clubSpecificMessage, club.toUpperCase());
    },
    showQR(club, qrImageUrl) {
      let options = Object.assign({}, BASE_MODAL, {
        html: `<h4 class="tulum-party" style="text-shadow: #23bac4 1px 4px 11px;">TULUM.PARTY</h4>
               <hr><p style="color:#efefef !important;">Scan the QR and send us a message with your inquiry. We'll be glad to help.</p>`,
        imageWidth: 270,
        imageHeight: 270,
        showDenyButton: false,
        showConfirmButton: false,
        footer: '<small style="color:#efefef !important;"> We operate from 9AM - 10PM / Answer within 15 mins</small>',
        imageUrl: qrImageUrl,
        imageAlt: 'QR for Contact',
      });
      Swal.fire(options);
    },
    replaceTag(string, club) {
      return string.replace('{{club}}', club);
    },
    mailto(club) {
      return this.mailAddress ? `mailto:${this.mailAddress}` : `mailto:bookings+${club.toLowerCase().replaceAll("@", "").replaceAll("|", "").replaceAll(/\s/g, '-') || ''}@tulum.party`;
    },
    mailFooter(club) {
      return `<div class="text-center">
                <a href="${this.mailto(club)}" class="btn-link">via email</a>
                <hr>
                <small style="font-weight: bold">Reserve Your Spot Now</small>
                <br>
                <br>
                <small>We operate from 9AM - 10PM <br>Answer within 15 mins</small>
              </div>`;
    },
  },
  data() {
    return {
      waQR: 'https://imgix.cosmicjs.com/c3b60240-f712-11ec-bf2b-e93971fa55b1-image.png',
      smsQR: 'https://imgix.cosmicjs.com/140a0b30-f22f-11ec-a2eb-c1653f3f9199-telegram-cloud-photo-size-1-5014946017338042974-x.jpg',
    };
  },
};
</script>


